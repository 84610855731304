<template>
    <div class="min-vh-100 p-3">
Nam želite kaj sporočiti?
        <br>
        Report a bug <br>
        Suggest improvement<br>
        Praise<br>
        Other <br>
    </div>
</template>

<script>
// @group DEVELOPER
export default {
    name: "Community",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
